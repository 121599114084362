import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const TeamMemberPost = ({
  className,
  thumbUrl,
  name,
  slug,
  companyPosition,
  email,
}) => {
  console.log("file: index.js:15  name:", name)
  // Add all classes to an array
  const addAllClasses = ["teamMember_post"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <Fade up delay={200}>
      <div className={addAllClasses.join(" ")}>
        <div className="thumbnail">
          <Image fluid={thumbUrl} alt={name} />
        </div>
        <div className="content">
          <h3 className="title">{name}</h3>
          <p className="excerpt">{companyPosition}</p>
          {name !== "Kasha" && (
            <a href={"mailto:" + email} className="email">
              {email}
            </a>
          )}
          {/* <Link
            to={`${slug}/`}
            className="read_more"
            aria-label="read more about the treatment"
          >
            Find out more about {name.split(" ")[0]}
          </Link> */}
        </div>
      </div>
    </Fade>
  )
}

TeamMemberPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.object,
  companyPosition: PropTypes.string,
  excerpt: PropTypes.string,
  name: PropTypes.element,
  slug: PropTypes.string,
}

export default TeamMemberPost
