import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  width: 100%;
  padding: 0 0 0px;
  @media only screen and (max-width: 1440px) {
    padding: 0px 0 0px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0px 0 0px;
  }
  @media (max-width: 990px) {
    padding: 0px 0 0px;
  }
  @media (max-width: 767px) {
    padding: 0 0 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 0 0px;
  }

  .container {
    .text {
      text-align: center;
      margin: 0 auto;
      max-width: 860px;
      margin-top: 40px;
      @media (max-width: 990px) {
        margin-top: 24px;
      }
    }
  }
`

export const TeamWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  margin-top: 180px;
  @media only screen and (max-width: 1440px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 34px;
    margin-top: 180px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 30px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 160px;
    font-size: 28px;
  }

  .teamMember_post {
    position: relative;
    width: calc(100% / 2 - 70px);
    padding: 0px 32px 32px;
    box-shadow: 0 0 100px rgba(175, 182, 194, 0.3);
    background-color: ${themeGet("colors.white", "#ffffff")};
    margin-bottom: 190px;
    text-align: center;
    @media only screen and (max-width: 1360px) {
      width: calc(100% / 2 - 30px);
      margin: 0 0 160px;
    }
    @media only screen and (max-width: 991px) {
      width: calc(100% / 2 - 30px);
      margin: 0 0 160px;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 160px;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
      padding: 0px 16px 24px;
      margin-bottom: 150px;
    }
    &:last-child {
      @media only screen and (max-width: 480px) {
        margin-bottom: 50px;
      }
    }

    .read_more {
      color: ${themeGet("colors.secondaryTextColor", "#297E7F")};
      font-size: 16px;
      font-weight: 300;
    }

    .email {
      margin-top: auto;
      font-size: 16px;
    }

    .thumbnail {
      width: 200px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%);

      img {
        border-radius: 50%;
      }
    }

    .content {
      h3 {
        margin: 130px 0 0px;
        font-size: 30px;
        line-height: 36px;
        font-weight: 300;
        letter-spacing: 1.28px;
        @media only screen and (max-width: 1440px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 1360px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media only screen and (max-width: 991px) {
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 8px;
        }
      }

      p {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 28px;
        margin-top: 8px;
        color: ${themeGet("colors.textColor", "#294859")};
        @media only screen and (max-width: 1360px) {
          font-size: 15px;
        }
        @media only screen and (max-width: 991px) {
          line-height: 25px;
          /* color: #616970; */
        }
      }
    }
  }
`

export const ContentSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -70px;
  width: 100%;
  color: ${themeGet("colors.textColor", "#333333")};

  .content {
    padding: 40px 30px;
    width: 800px;
    background-color: #f8f2eb;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 48px;
      letter-spacing: 0.8px;
      line-height: 55px;
      font-weight: 400;
      margin: 8px 0;
    }
    h2 {
      font-size: 32px;
      letter-spacing: 0.8px;
      line-height: 55px;
      font-weight: 400;
      margin: 8px 0;
    }
    h3 {
      font-size: 32px;
      letter-spacing: 0.64px;
      line-height: 55px;
      font-weight: 400;
      margin: 8px 0;
    }
    h4 {
      font-size: 24px;
      letter-spacing: 0.48px;
      line-height: 55px;
      font-weight: 400;
      margin: 8px 0;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      margin-top: 24px;
    }

    img {
      max-width: 100%;
      margin-bottom: 24px;
    }

    ul {
      /* margin-bottom: 32px; */
    }

    li {
      p {
        color: #297e7f;
        font-size: 14px;
        text-align: center;
        margin: 2px;
      }
    }
  }
`

export default SectionWrapper
