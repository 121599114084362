import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BreadCrumbsAbout from "../../containers/BreadcrumbsAbout"
import ChangeYourLife from "../../containers/ChangeYourLife"
import Layout from "../../containers/Layout"
import PageTitle from "../../containers/PageTitle"
import SEO from "../../components/SEO"
import TeamMembers from "../../containers/TeamMembers"
import { ContentWrapper } from "../../containers/elegant.style"

const MeetTheTeam = ({ data }) => {
  const {
    seoDetails,
    title,
    subtitle,
    text,
    additionalText,
    teamMembers,
  } = data.contentfulMeetTheTeam
  return (
    <>
      <SEO
        title={seoDetails.metaTitle}
        shortDesc={seoDetails.metaShortDescription}
        description={seoDetails.metaDescription}
      />
      <Layout>
        <ContentWrapper>
          <BreadCrumbsAbout currentPage="Meet the team" />
          <PageTitle title={title} subtitle={subtitle} />
          <TeamMembers
            text={text}
            teamMembers={teamMembers}
            additionalText={additionalText}
          />
          <ChangeYourLife
            title="Ready to change your life?"
            text="Begin your journey with us today. With Elegant Beauty clinics across the Bournemouth, Poole and Southampton area, we invite you to visit us for a free consultation at your convenience."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default MeetTheTeam

export const meetTheTeamQuery = graphql`
  query MeetTheTeam {
    contentfulMeetTheTeam {
      title
      subtitle
      text
      additionalText {
        json
      }
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
      teamMembers {
        slug
        name
        email
        phone
        seoDetails {
          metaDescription
          metaShortDescription
          metaTitle
        }
        mainImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        companyPosition
        thumbnail {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
