import Fade from "react-reveal/Fade"
import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
/* eslint-disable react/display-name */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Container from "../../components/UI/Container"
import TeamMemberPost from "../../components/TeamMemberPost.js"
import Text from "../../components/Text"
import SectionWrapper, {
  ContentSection,
  TeamWrapper,
} from "./teamMembers.style"

const TeamMembers = ({ text, teamMembers, additionalText }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={node.data.target.fields.file["en-US"].url} alt="blogpost" />
      ),
    },
  }
  return (
    <SectionWrapper>
      <Container width="1340px">
        <Fade up delay={200}>
          <Text content={text} className="text" />
        </Fade>
        <TeamWrapper>
          {teamMembers.map((item, index) => {
            return (
              <TeamMemberPost
                key={index}
                slug={item.slug}
                name={item.name}
                email={item.email}
                thumbUrl={item.thumbnail.fluid}
                companyPosition={item.companyPosition}
              />
            )
          })}
          <ContentSection>
            <Fade up delay={300}>
              <div className="content">
                {documentToReactComponents(additionalText.json, options)}
              </div>
            </Fade>
          </ContentSection>
        </TeamWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default TeamMembers
